footer {
  background: var(--color-primary);
  padding: 3rem 0;
  text-align: center;
  font-size: .9rem;
  margin-top: 7rem;
}

footer a {
  color: var(--color-bg)
}

.footer__logo {
  font-size: 2rem ;
  font-weight: 500;
  margin-bottom: 2rem;
  display: inline-block;
}

.permalinks {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin: 0 auto 3rem;
}

.footer__socials {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 4rem;
}

.footer__socials a {
  background: var(--color-bg);
  color: var(--color-white);
  padding: .8rem;
  border-radius: .7rem;
  display: flex;
  border: 1px solid transparent;
  transition: var(--transition);
}

.footer__logo a:hover {
  background: transparent;
  color: var(--color-bg);
  border-color: var(--color-bg);
}

.footer__copyright {
  margin-bottom: 4rem;
  color: var(--color-bg);
}

/* ===================== Breakpoints ========================== */
/* ===================== Small Devices ========================== */
@media screen and (max-width: 600px) {
  .permalinks {
    flex-direction: column;
    gap: 1.5rem;
  }

  .footer__socials {
    margin-bottom: 2.6rem;
  }

  .elevator-button {
    bottom: 7rem !important; /* Ajustez cette valeur selon vos besoins */
  }

  .elevator-text {
    display: none !important; /* Masquer le texte */
  }

  .elevator-icon {
    display: block !important; /* Afficher l'icône */
    text-align: center; /* Centrer l'icône */
  }
}

/* ===================== Elevator ========================== */

.elevator-text {
  display: block; /* Afficher le texte */
}

.elevator-icon {
  display: none; /* Masquer l'icône */
}


.elevator-button {
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  padding: 0.5rem 1rem;
  background-color: var(--color-primary); /* ou toute autre couleur de votre choix */
  color: #fff;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.elevator-button:hover {
  background-color: var(--color-primary-dark); /* ou toute autre couleur de votre choix */
}
