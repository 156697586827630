.container.partners__container {
  display: grid; /* Utiliser le système de grille */
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Créer autant de colonnes que nécessaire pour accueillir les logos */
  gap: 30px 60px; /* Espacement vertical et horizontal entre les logos */
  justify-items: center; /* Centrer les logos dans chaque cellule de grille */
}

.partner-logo {
  width: 150px; /* Largeur fixe pour chaque logo */
}

.partner-logo:nth-child(2n) { /* 2, 4, 6... */
  margin-top: 50px; /* Décaler vers le bas */
}

.partner-logo img {
  max-width: 100%; /* Permettre au logo de prendre toute la largeur de son conteneur */
  filter: grayscale(100%); /* Rendre les logos en niveaux de gris */
  transition: transform 0.3s, filter 0.3s; /* Transition pour l'effet de survol et le filtre */
}

.partner-logo:hover img {
  transform: scale(1.1); /* Agrandir le logo lors du survol */
  filter: grayscale(0%); /* Retirer le filtre en niveaux de gris lors du survol */
}


.container.testimonials__container {
  width: 40%;
  padding-bottom: 4rem;
}

.client__avatar img {
  width: 4rem;
  aspect-ratio: 1/1;
  overflow: hidden;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin: 0 auto 1rem;
  border: .4rem solid var(--color-primary-variant);
}

.testimonial {
  background: var(--color-bg-variant);
  text-align: center;
  padding: 2rem;
  border-radius: 2rem;
  user-select: none;
}

.client__review {
  color: var(--color-light);
  font-weight: 300;
  display: block;
  width: 80%;
  margin: .8rem auto 0;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  background: var(--color-primary);
}

/* ===================== Breakpoints ========================== */
/* ===================== Medium Devices ========================== */
@media screen and (max-width: 1024px) {
  .container.testimonials__container {
    width: 60%;
  }
}


/* ===================== Small Devices ========================== */
@media screen and (max-width: 600px) {
  .container.testimonials__container {
    width: var(--container-width-sm);
  }

  .client__review {
    width: var(--container-width-sm);
  }
}

/* ===================== Linkedin ========================== */

.partners-invitation {
  text-align: center;
  margin-top: 2rem;
  font-style: italic;
}

.partners-invitation a {
  color: var(--color-primary); /* ou toute autre couleur de votre choix */
  text-decoration: underline;
}
