.portfolio__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2.5rem;
}
.portfolio__item {
  background: var(--color-bg-variant);
  padding: 1.3rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
  display: flex; /* Ajouté */
  flex-direction: column; /* Ajouté */
  justify-content: space-between; /* Ajouté */
}


.portfolio__item:hover {
  border-color: var(--color-primary-variant);
  background: transparent;
}

.portfolio__item-img {
  border-radius: 1.5rem;
  overflow: hidden;
}

.portfolio__item h3 {
  margin: 1.2rem 0 2rem;
}

.portfolio__item-cta {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem ;
}

/* ===================== Breakpoints ========================== */
/* ===================== Medium Devices ========================== */
@media screen and (max-width: 1024px) {
  .portfolio__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
}


/* ===================== Small Devices ========================== */
@media screen and (max-width: 600px) {
  .portfolio__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}

/* ===================== Flair ========================== */

.portfolio__item-tech {
  display: flex;
  flex-wrap: wrap; /* Permet aux flairs de passer à la ligne */
  gap: 0.5rem;
  margin-bottom: 1rem;
  max-width: calc(25% * 4); /* Largeur maximale pour 4 flairs */
}


.tech-flair {
  background-color: var(--color-primary-variant);
  color: #fff;
  padding: 0.2rem 0.5rem;
  border-radius: 0.5rem;
  font-size: 0.8rem;
}
