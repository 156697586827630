.container.contact__container {
  width: 58%;
  display: grid;
  grid-template-columns: 30% 58%;
  gap: 12%;
}

.contact__options {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.contact__option {
  background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid transparent;
  transition: var(--transition);
}

.contact__option:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
}

.contact__option-icon {
  font-size: 1.5rem;
  margin-bottom: .5rem;
}

.contact__option a {
  margin-top: .7rem;
  display: inline-block;
  font-size: .8rem;
}

/* ===================== Form ========================== */
form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

input, textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: .5rem;
  background: transparent;
  border: 2px solid var(--color-primary-variant);
  resize: none;
  color: var(--color-white);
}

/* ===================== Breakpoints ========================== */
/* ===================== Medium Devices ========================== */
@media screen and (max-width: 1024px) {
  .container.contact__container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}


/* ===================== Small Devices ========================== */
@media screen and (max-width: 600px) {
  .container.contact__container {
    width: var(--container-width-sm);
  }
}

/* ===================== Message done ========================== */

.success-message {
  padding: 15px;
  border-radius: 5px;
  background-color: #4CAF50; /* Vert pour le succès */
  color: #fff; /* Texte blanc */
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Ombre légère pour donner de la profondeur */
  transition: transform 0.3s ease, opacity 0.3s ease; /* Transition douce pour les animations */
}

/* Animation pour faire apparaître le message doucement */
@keyframes fadeIn {
  from {
      opacity: 0;
      transform: translateY(-20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

.success-message {
  animation: fadeIn 0.5s ease forwards;
}
